.nav-link.active {
  color: #222 !important;
  background-color: #fff !important;
}
.nav-link{
  font-size: 16px !important;
  font-family: roboto !important;
  /* color: #fff !important; */
}
/* .nav-link.active1 {
  background-color: blue;
} */