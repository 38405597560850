.navbar {
  position: fixed;
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
}

.dropdownMenu {
  right: 0px !important;
  left: auto !important;
  width: 295px;
  /* height: 8000px !important; */
  min-height: auto !important;
  height: auto !important;
 max-height: 270px !important;
}
.dropdownMenu::-webkit-scrollbar{
  display:none;
}
.dropdownMenu1 {
  right: 0px !important;
  left: auto !important;
  /* width: 500px; */
}
.navbarNav i {
  font-size: 25px;
}
.teacheraccept {
  padding-left: 2%;
  position: relative;
}
.iconText:hover{
  /* display:flex; */
  /* align-items: center; */
  background: rgb(247, 247, 247);
}
