.navbarHeader {
  padding: 3px 5px;
  background: #4A1D75;
}

.navbarBrand {
  font-size: 22px !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
    font-family: roboto !important;
}

.mainSidebar {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 240px;
  z-index: 1035;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  background: #4A1D75;
  /* border: 1px solid red; */
  /* box-shadow: rgba(146, 153, 184, 0.063) 0px 0px 30px; */
  overflow-y: auto;
}

.mainSidebar ul {
  padding-left: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 0px;
}

.mainSidebar li {
  padding: 2px 0px;
  cursor: pointer;
  text-align: left;
}

.mainSidebar a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  font-weight: 400;
  padding: 10px 15px !important;
}

.sidebaricons {
  color: #dadada;
  margin-right: 10px;
  font-size: 20px !important;
  margin-right: 6px;
  position: relative;
  top: 3px;
  width: 30px;
}
.nav-link.active {
  color: #333 !important;
  background-color: #fff !important;
}
