.logo {
  height: 200px;
  width: 200px;
  border: 3px dotted #000;
  padding: 5px;
}
.imageLogo {
  height: 200px;
  width: 200px;
}
.input-group-text {
  width: 30% !important;
  border-radius: 40px;
}
.CurriculamHeader {
  display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
  /* padding: 15px; */
}
.text-align {
  font-size: 18px;
  font-weight: 500;
}
@media only screen and (max-width: 1280px) {
  .CurriculamHeader {
    width: 84% !important;
  }
}
.btn btn-sm add-btn {
  background-color: #4a1d75 !important;
}
@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");
body {
  margin: 30px;
}
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
}

.search {
  display: inline-block;
}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input {
  text-indent: 32px;
}
.search .fa-search {
  position: absolute;
  top: 10px;
  left: 10px;
}

/* .search .fa-search {left: auto; right: 10px;} */

.table_container table thead tr th {
  background-color: #f1e3fe !important;
  padding-bottom: 16px !important;
}
.maindiv {
  height: 80vh !important;
  overflow: scroll !important;
  border: 1px solid red;
  visibility: visible;
}
.uploadButton {
  background: #4a1d75;
  color: white;
  padding: 5px 9px;
  border-radius: 5px;
}
.uploadButtonChange {
  background: whitesmoke;
  color: black;
  border-color: #4a1d75;
  padding: 5px;
  border-radius: 5px;
}
@media (max-width: 650px) {
  .searchInput {
    width: 140px !important;
  }
}

.error {
  border: 1px solid red;
}
