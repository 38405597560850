.crud-btn1 {
  background-color: rgb(255, 231, 168);
  color: rgb(234, 183, 49);
  padding: 5px 5px;
  font-size: 11px;
  text-decoration: none;
  border-radius: 3px;
  font-weight: 500;
}
.crud-btn2 {
  background-color: rgb(222, 242, 233);
  color: #49af48;
  font-size: 11px;
  padding: 5px 5px;
  text-decoration: none;
  border-radius: 3px;
  font-weight: 500;
}
.crud-btn3 {
  background-color: rgb(228, 152, 152);
  color: #ed2d2d;
  padding: 5px 5px;
  font-size: 11px;
  text-decoration: none;
  border-radius: 3px;
  font-weight: 500;
}
.search-con {
  position: relative !important;

  padding: 0 20px !important;
}

.icon123 {
  position: absolute !important;
  top: 0;
  left: 25px;
  padding-top: 13px;
}

.container23 {
  background-color: white;
  margin: 0 25px !important;
}

#search-input {
  border-radius: 50px;
}

.add-facalities-div {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.add-input-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid black;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
}

.add-btn {
  padding: 7px 15px;
  border-radius: 6px;
  background-color: rgb(74, 29, 117);
  color: white;
  border: none;
}

.custom-css-add {
  border-radius: 6px !important;
}
