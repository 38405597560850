@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");
/* body { margin: 30px; } */
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
  margin-bottom: 10px !important;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}


.table_container2 table thead tr th{
    background-color: #F1E3FE !important;
  padding-bottom:18px !important;
}