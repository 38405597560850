/* 
* {
  box-sizing: border-box;
}
.topCardLeft h5 {
  font-size: 14px;
  margin-bottom: 12px;
  text-align: left;
  color: #9c9c9c;
  font-weight: 400;
}

.topCardLeft h2 {
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 0;
}

.topCardLeft span {
  font-size: 12px;
  background: #0079d2;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}


.topCardRight {
  margin-left: 80px;
  display: flex;
  justify-content: space-around;
  align-content: space-around;
}

.topCardRight img {
  width: 58px;
}


.queriesDiv h5 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}
.assignmentDiv1 h5 {
  margin-top: 45px !important;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}

.assignmentDiv p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.queriesDiv p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  line-height: 27px;
}

.hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.curriculum {
  margin-right: 10px;
  font-size: 15px;
}

.subject,
.chapter {
  float: left;
  margin-right: 10px;
  font-size: 15px;
}

.queryDiv {
  line-height: 28px;
}

.queryDiv .subject {
  float: none;
  margin-right: 10px;
  font-size: 14px;
  width: 100%;
}

.queryDiv .topic {
  float: none;
  margin-right: 10px;
  font-size: 14px;
}

.queryDiv .chapter {
  margin-right: 10px;
  font-size: 14px;
}

.sessionsDiv h2 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 0;
  color: #333;
}

.sessionsDiv p {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0;
}

.sessionsDiv span {
  font-size: 12px;
  background: #0079d2;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.sessionsDiv button {
  margin-top: 12px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  background-color: #ea9f07;
  border-color: #ea9f07;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}
.card {
  margin: 25px;
  padding: 20px;
  width: 272px;
  height: 142px;
  top: 188px;
  left: 297px;
  border-radius: 8px;
  background: #fbb0b8;
}
.card2 {
  margin: 25px;
  padding: 20px;
  width: 272px;
  height: 142px;
  border-radius: 8px;
  background: #ffd59c;
}
.card3 {
  margin: 25px;
  width: 272px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  background: #a9dbff;
}
.texts {
  color: rgba(0, 0, 0, 0.7);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.circle {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  
}
.circle1 {
  width: 100%;
  height: 100px;
    width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  
}
.img_con {
  margin-top: 35px;
  height: 86px;
  background-color: white;
  padding: 2px;
  border-radius: 50%;
}

.filestudent {
  display: flex;
  gap: 2;
}
.totalcurri {
  display: flex;
  justify-content: space-between;
}
.totalstudent {
  display: flex;
  justify-content: space-between;
}
.totalsession {
  display: flex;
  justify-content: space-between;
}
.semicircle {
}
.totalassesment {
  display: flex;
  justify-content: center;
  width: 70%;
} */
.admindashome {
  /* margin-left: 60px; */
}

.main {
  display: flex;
  justify-content: space-between;
}

.card {
  background: #a9dddd;
}

.curri {
  display: flex;
  justify-content: space-between;
}

.card2 {
  background: #fbb0b8;
}

.card3 {
  background: #ffd59c;
}

.common_card {
  width: 272px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
}

.submain {
  display: flex;
  justify-content: space-between;

  /* padding-top: 3%;
  padding-left: 7%;
  padding-right: 7%; */
}

@media (max-width:1064px) {
  .common_card {
    width: 200px;
  }
}

.center_common_card {
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  width: 49% !important;
}

@media (max-width:880px) {
  .common_card {
    width: 80% !important;

  }

  .main {
    flex-direction: column;
    align-items: center;
  }

  .submain {
    flex-direction: column;
    align-items: center;
  }

  .center_common_card {
    width: 80% !important;
    margin: 10px 0px;
  }

  .card2 {
    margin: 20px 0px;
  }
}

@media (max-width:526px) {
  .center_common_card {
    width: 100%;
  }

  .common_card {
    width: 100%;
  }
}

.assess {
  display: flex;
  justify-content: space-between;
}


.sessio {
  display: flex;
  justify-content: space-between;
}




.card4 {
  /* margin: 25px; */
  background: #CCBFEB;
}

.card5 {
  /* margin: 25px; */
  background: #a9dbff;
}

.cardcompletedpend {
  display: flex;
  justify-content: space-around;

}

.sessioncards {
  display: flex;
  justify-content: space-around;
  width: 30%;
}

.queriescard {
  display: flex;
  justify-content: space-around;
  width: 30%;

}

.completesession {
  /* margin: 25px; */
  /* margin-left: 40px; */
  /* width: 200px; */
  height: 140px;
  padding: 20px;
  background: #CCBFEB;
  border-radius: 8px;

}

.pendingsession {
  /* margin: 25px; */
  /* width: 200px; */
  height: 140px;
  padding: 20px;
  background: #CCBFEB;
  border-radius: 8px;

}

.completequeries {
  /* margin: 25px; */
  /* width: 200px; */
  height: 140px;
  /* padding: 20px; */
  background: #A9DBFF;
  border-radius: 8px;
}

.pendingcard {
  /* margin: 25px; */
  /* width: 200px; */
  height: 140px;
  padding: 20px;
  background: #A9DBFF;
  border-radius: 8px;
}

.student {
  display: flex;
  justify-content: space-between;
}

.teacher {
  display: flex;
  justify-content: space-between;
}

totalSession {
  /* width: 50%; */
}

@media screen and (max-width: 1860px) {



  .sessioncards {
    display: flex;
    justify-content: space-around;
    width: 34%;
  }

  .queriescard {
    display: flex;
    justify-content: space-around;
    width: 34%;

  }
}

@media screen and (max-width: 1650px) {
  .sessioncards {
    display: flex;
    justify-content: space-around;
    width: 38%;
  }

  .queriescard {
    display: flex;
    justify-content: space-around;
    width: 38%;

  }

}

@media screen and (max-width: 1650px) {
  .sessioncards {
    display: flex;
    justify-content: space-around;
    width: 40%;
  }

  .queriescard {
    display: flex;
    justify-content: space-around;
    width: 40%;

  }
}

@media screen and (max-width: 1450px) {
  .sessioncards {
    display: flex;
    justify-content: space-around;
    width: 42%;
  }

  .queriescard {
    display: flex;
    justify-content: space-around;
    width: 42%;

  }
}

@media screen and (max-width:1400px) {
  .sessioncards {
    display: flex;
    justify-content: space-around;
    width: 45%;
  }

  .queriescard {
    display: flex;
    justify-content: space-around;
    width: 45%;

  }
}

@media screen and (max-width:1350px) {
  .sessioncards {
    display: flex;
    justify-content: space-around;
    width: 48%;
  }

  .queriescard {
    display: flex;
    justify-content: space-around;
    width: 48%;

  }
}

@media screen and (max-width:1300px) {




  .sessioncards {
    display: flex;
    justify-content: space-around;
    width: 48%;
  }

  .queriescard {
    display: flex;
    justify-content: space-around;
    width: 48%;

  }
}

@media screen and (max-width:1250px) {


  .card4 {
    height: 142px;
    background: #CCBFEB;


  }

  .card5 {
    height: 142px;
    border-radius: 8px;
    background: #a9dbff;
  }


  .sessioncards {
    display: flex;
    justify-content: space-around;
    width: 50%;
  }

  .queriescard {
    display: flex;
    justify-content: space-around;
    width: 50%;

  }
}