.helpRequest{
  /* margin-top: 100px; */
}
.tutorDiv p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  line-height: 27px;
}

.dashimg {
  height: 60vh;
  overflow: hidden;
}

.hr {
  margin-top: 15px;
  margin-bottom: 10px;
  clear: both;
}

.curriculum,
.subject,
.topic,
.chapter {
  float: left;
  margin-right: 10px;
  margin-top: 8px;
  font-size: 16px;
  line-height: 20px;
}

.tutorFeatures {
  /* background: #e9edf6; */
  background: #e9eaec;
  margin-top: 10px;
  padding: 10px;
  overflow: hidden;
}

.tutorFeatures span {
  cursor: pointer;
  padding: 4px 10px;
  margin-left: 10px;
  border-radius: 2px;
  float: right;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.tutorFeatures .replay {
  background: #7986cb;
}

.tutorFeatures .resolve {
  background: #66bb6a;
}

.tutorFeatures .feedback {
  background: #42a5f5;
}

.raiseQueryDiv select {
  height: 42px;
}

.status {
  font-weight: 500;
}

.scheduledDate {
  margin-left: 20px;
  font-weight: 500;
}

.open {
  display: block;
}

.close {
  display: none;
}
.requestQuery{
  background-color: #4A1E75 !important;
  color: #fff !important;
}
.backrequests{
   background-color: #4A1E75 !important;
  color: #fff !important;
}
.submitRequest{
    background-color: #4A1E75 !important;
    color: #fff !important;
    padding: 10px 15px !important;
    font-size: 16px !important;
}
