.sessionsDiv h2 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 0;
  color: #333;
}
.dashimg {
  /* height: 60vh;
  overflow: hidden; */
    background-image: url("../../../assets/Banners/group3.png");
     overflow: hidden;
 padding-top: 320px;
  padding-bottom: 230px;
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
}
.styleTab{
  border-radius: 40px;
  /* border: 2px solid red; */
}
.sessionsDiv p {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0;
}

.sessionsDiv span {
  font-size: 12px;

  padding: 5px 0;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.sessionsDiv button {
  margin-top: 12px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  background-color: #ea9f07;
  border-color: #ea9f07;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}

.sessionPreviewDiv {
  background: #fff;
  margin-bottom: 20px;
  width: 60% !important ;
  border-radius: 6px;
  border: 1px solid gray;
  padding: 15px;
  box-shadow: 0 0 11px -6px rgb(187 187 187 / 65%);
  font-size: 14px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.sessionPreviewDiv table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.sessionPreviewDiv table td {
  border: 0px;
}

.sessionPreviewDiv h2 {
  font-size: 22px;
  margin-bottom: 17px;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.testBack {
  padding: 7px 15px !important;
  font-size: 16px !important;
}
.testBack1{
  position: absolute;
  right: 10px;

}

.testStart {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

@media only screen and (max-width: 1100px) {
  .sessionPreviewDiv{
    width: 85% !important;
  }
  }
@media only screen and (max-width: 992px) {
  .sessionPreviewDiv{
    width: 95% !important;
  }
  }