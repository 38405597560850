.navbar1{
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 75px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
  /* margin-bottom: 10px; */
}

.dropdownMenu {
  right: 0px !important;
  left: auto !important;
  width: 295px;
 min-height: auto !important;
 height: auto !important;
 max-height: 270px !important;
}
.dropdownMenu::-webkit-scrollbar{
  display:none;
}
.dropdownMenu1 {
  right: 0px !important;
  left: auto !important;
  /* width: 500px; */
}
.logo{
  border: 2px solid blue;
}
/* .active{
  background-color: #e6e6e6 !important;
} */
.navbarNav1{
  display: flex;
  justify-content: space-between;
  /* width: 70%; */
}
.navbarNav1 i {
  font-size: 30px;
  background-color: none;
}
.dd ul li{
  font-size: 18px;
  /* width: 140px; */
  line-height: 40px;
  padding-right: 20px;
  text-align: center;
  /* border: 1px solid red; */
}
.navbarNav1 li:hover{
  background-color: lightgray;
  /* padding: 2px; */
}
.map {
  height: 10px;
}
.flat{
  background-color:red
}
.studentNotification{
   padding-left: 2%;
   position: relative;
}
.iconText{
  /* display:flex; */
  align-items: center;
  /* border: 1px solid red; */
}
.iconText:hover{
  background: rgb(247, 247, 247);
}