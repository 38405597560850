.imagetheme{
  background-image: url("../../../assets/Banners/group3.png");
   overflow: hidden;
  padding-top: 360px;
  padding-bottom: 210px;
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
}
@media only screen and (max-width: 1280px){
  .imagetheme{
  background-image: url("../../../assets/Banners/group3.png");
   overflow: hidden;
  /* padding-top: 190px;
  padding-bottom: 210px; */
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
}
}
/* .selectinput{
  color: black;
} */
.progress {
  height: 10px;
  border: 1px solid #f1f1f1;
  background: #e0e0e0;
  border-radius: 10px;
}
.dashimg {
  height: 60vh;
  overflow: hidden;
}

.progressBar {
  height: 8px;
  border-radius: 10px;
}

.chaptersDiv table {
  background: #fff;
}

.chaptersDiv table td:nth-child(2) {
  font-weight: 500;
  width: 200px;
}

.chaptersDiv table td:nth-child(3) {
  width: 40%;
}

.modalBody {
  min-height: 400px;
}

.notAvailable {
  text-align: center;
  width: 100%;
  padding-top: 150px;
  font-size: 18px;
  font-weight: 500;
}
.txt {
  display: flex;
  /* position: absolute; */
  align-items: center;
  width: 100%;
  height: 80px;
  left: 63px;
  top: 437px;
  font-size: 1.7rem !important;
  background: rgb(245, 240, 240);
  border-radius: 3px;
  padding: 0px 20px;
}
.area{
  display: flex;
  /* position: absolute; */
  align-items: center;
  width: 100%;
  height: 360px;
  left: 63px;
  top: 437px;
  font-size: 1.7rem !important;
  background: rgb(245, 240, 240);
  border-radius: 3px;
  padding: 0px 20px;
}
.area p{
  font-size: 12px;
}
.area input{
  font-size: 12px;
}
.txt p{
  font-size: 14px;
}

.box {
  /* box-sizing: border-box; */

  /* position: absolute; */
  width: auto;
  height: auto;
  left: 34px;
  top: 341px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
}


/* ============================================================ */
.curri_headings{
  font-size: 2.3rem !important;
    font-weight: 600;
    margin-bottom: 20px !important;
}
.banner{
  width: 100%;
}
.relative{
  position: relative !important;
}

.drop_icon{
  position: absolute !important;
  top: 7px;
  right: 10px;
  
}
.banner img{
  background: cover !important;
  width: 100%;
}


.resTable{
  /* overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;   */
}

.resTable::-webkit-scrollbar {
  display: none;
}


@media screen and (min-width: 1200px) {
  .txt {
    width: 100%;
  }
}

@media screen and (min-width: 1100) {
  .txt {
    width: 900px;
  }
}
@media screen and (max-width: 750) {
  .txt {
    width: 200px;
  }
}
