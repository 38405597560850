
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
*{
    
    padding: 0;
    margin: 0;
    /* align-items: center; */
}
.Addnotification_content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}
.Addnotification_content h1{
    margin-top: 100px;
    font-size: 24px;
    font-weight: 600;
    color: #501a74;
}
.notification-head{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 89%;
    align-items: flex-end;
    margin-bottom: 10px;
}
.notification_container{
    border: 1px solid grey;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    width: 90%;
    margin-top: 10px;
    background-color: #fff;
    /* border-radius: 10px; */
    /* font-size: 24px; */
    line-height: 32px;
    color: #4a1d75;
}
.notification_inputs{
    width: 90%;
    height: 100%;
    border-radius: 10px;
   /* align-items: center;
   justify-content: center;
   display: flex;
    flex-direction: column; */
   
    margin: 20px 0px;
    
}
 /* input::placeholder{
font-size: 18px;
} */
.notification_inputs textarea{
    cursor: pointer;
}
.submit_btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    
}
.cancel_btn #cancel_btn{
border-radius: 40px;
background-color:  #4a1d75;
border: none;
width: 140px;
text-align: center;
padding: 6px;
color: #fff;
height: auto;

}
.submit_btn #submit_btn{
    
 
    /* font-size: 24px;
padding: 10px 25px 18px 25px;
width:230px;
text-align: center;
align-items: center;
height: 60px;
border-radius: 10px;
background-color: #4a1d75;
color: #fff; */

    border-radius: 40px;
    background-color:  #4a1d75;
    border: none; 
    width: 140px;
    text-align: center;
    /* padding: 6px; */
    color: #fff;
    height: auto;
}



/* table */
.notification_table-top{
    width: 100%;
    height: 100px;
    display: flex;
    
    /* align-items: center; */
    justify-content: center;
}
.head_table{
    width: 50%;
    display: flex;
    align-items: end;
    text-align: left;
    justify-content: left;
    padding: 0px 20px;
}
.btn_table{
    width: 50%;
    display: flex;
    align-items: end;
    padding: 0px 20px;
    justify-content: flex-end;
}
.btn_table #add_btn{

    /* font-size: 24px;
padding: 5px 25px 18px 25px;
width:230px;
align-items: center;

height: 50px;
border-radius: 10px;
background-color: #4a1d75;
color: #fff; */

border-radius: 40px;
background-color: rgb(74, 29, 117);
border: 2px solid rgb(74, 29, 117);
width: 140px;
text-align: center;
padding: 6px;
color: white;
height: auto;
}
.head_table H1{
    padding: 10px;
    color: #501a74;
    font-size: 24px;
    font-weight: 600
    
}
.table_container {
     width: 100%;
     padding: 20px;
}
.notification_table table{
    width: 100%;
    padding: 20px 40px !important;
}
.notification_table .table th {
    color: rgb(32, 99, 155);
}
.notification_table table thead tr th{
    background-color:  #f1e3fe;
    color: #4a1d75;
}
.del_btn{
    border: none;
    width: 60px;
    height: 27px;
    background-color: rgb(228, 152, 152);
}





