@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");
body { margin: 30px; }
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}
.videoHeader{
  display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
}

/* width */
.scroll-div::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
.scroll-div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll-div::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scroll-div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.overflowList{
  overflow: auto !important;
  height: 70vh !important;
}