.mainSidebar {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: #fff;
  overflow: hidden;
  width: 240px;
  z-index: 1030;
  box-shadow: 0 0 11px -6px rgb(0 121 210 / 65%);
}

.sidebar_links_container{
  background: #4A1D75 !important;
}

.nav-link.active {
  color: #333 !important;
  background-color: #fff !important;
}
.nav-link:hover {
  color: white !important;
}

.logo {
  text-align: center;
}

.logo img {
  height: 45px;
  margin: 5px auto;
  display: block;
}

.logo span {
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
  display: block;
}

.mainSidebar ul {
  padding-left: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 0px;
  top: 45px;
}

.mainSidebar li {
  cursor: pointer;
  padding: 2px 0px;
  text-align: left;
}

.mainSidebar .navLink {
  font-size: 14px;
  text-decoration: none;
  /* color: #333; */
  color: white;
  font-weight: 500;
  padding: 10px 15px !important;
}

.sideIcon {
  font-size: 20px !important;
  margin-right: 8px;
  position: relative;
  top: 2px;
  /* color: #a2a2a2; */
  color: darkgray !important;
  /* color: white; */
}
