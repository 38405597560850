.templateHeader {
  display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
}
.input-group-text {
  width: 30% !important;
  border-radius: 40px;
}
@media only screen and (max-width: 1280px) {
  .templateHeader {
  }
}
.table_container table thead tr th {
  background-color: #f1e3fe !important;
  padding-bottom: 16px !important;
}
.autocompl {
  padding: 30px !important;
}

.relative{
  position: relative !important;
  *border: 1px solid red;
  width: auto !important;
}

.drop_icon{
  position: absolute !important;
  top: 32px;
  right: 10px;
  
}