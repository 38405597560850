.staffHewader{
  display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
  margin-top: 10px !important;
}
@media only screen and (max-width: 1280px){
    .staffHewader{
  width: 74% !important;
}
}
@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");
body { margin: 30px; }
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}

.relative{
  position: relative !important;
 
  width: auto !important;
}

.drop_icon{
  position: absolute !important;
  top: 32px;
  right: 10px;
}